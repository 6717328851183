<template>
  <section id="dashboard-ecommerce">
    <div>
      <b-row>
        <b-col md="4">
          <b-card>
            <div class="d-flex justify-content-between ">
              <b-card-text>
                <strong>
                  Startup Programs
                </strong>
              </b-card-text>
              <feather-icon
                size="20"
                icon="GridIcon"
              />
            </div>
            <div class="d-flex align-items-start">
              <b-card-title
                class="mr-2"
                style="font-size: 2rem"
              >
                <strong>2345</strong>
              </b-card-title>
            </div>
            <div>
              <b-card-text>Applicants : 23</b-card-text>
              <div class="d-flex justify-content-between align-items-start">
                <b-card-text>My Applications : 23</b-card-text>
                <b-link to="/programs">
                  <feather-icon
                    icon="ArrowRightIcon"
                    size="20"
                  />
                </b-link>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card>
            <div class="d-flex justify-content-between ">
              <b-card-text>
                <strong>
                  Events
                </strong>
              </b-card-text>
              <feather-icon
                size="20"
                icon="ActivityIcon"
              />
            </div>
            <div class="d-flex align-items-start">
              <b-card-title
                class="mr-2"
                style="font-size: 2rem"
              >
                <strong>222</strong>
              </b-card-title>
            </div>
            <div>
              <b-card-text>Applicants : 23</b-card-text>
              <div class="d-flex justify-content-between align-items-start">
                <b-card-text>My Applications : 23</b-card-text>
                <b-link to="/incubator-portal/events">
                  <feather-icon
                    icon="ArrowRightIcon"
                    size="20"
                  />
                </b-link>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card>
            <div class="d-flex justify-content-between ">
              <b-card-text>
                <strong>
                  Competitions
                </strong>
              </b-card-text>
              <feather-icon
                size="20"
                icon="ZapIcon"
              />
            </div>
            <div class="d-flex align-items-start">
              <b-card-title
                class="mr-2"
                style="font-size: 2rem"
              >
                <strong>1845</strong>
              </b-card-title>
            </div>
            <div>
              <b-card-text>Applicants : 23</b-card-text>
              <div class="d-flex justify-content-between align-items-start">
                <b-card-text>My Applications : 23</b-card-text>
                <b-link to="/incubator-portal/competitions">
                  <feather-icon
                    icon="ArrowRightIcon"
                    size="20"
                  />
                </b-link>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <b-row class="match-height">
      <b-col md="12">
        <startup-statistics />
      </b-col>
      <b-col md="7">
        <mentor-statistics />
      </b-col>
      <b-col md="5">
        <partner-statistics />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col md="7">
        <program-statistics />
      </b-col>
      <b-col md="5">
        <student-statistics />
      </b-col>
    </b-row>
  </section>
</template>

<script>

import MentorStatistics from '@/views/dashboard/v1/components/incubator/MentorStatistics.vue'
import PartnerStatistics from '@/views/dashboard/v1/components/incubator/PartnerStatistics.vue'
import StudentStatistics from '@/views/dashboard/v1/components/incubator/StudentStatistics.vue'
import StartupStatistics from '@/views/dashboard/v1/components/incubator/StartupStatistics.vue'
import ProgramStatistics from '@/views/dashboard/v1/components/incubator/ProgramStatistics.vue'
import {
  BCard, BCardTitle, BCardText, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    StudentStatistics,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    MentorStatistics,
    PartnerStatistics,
    StartupStatistics,
    ProgramStatistics,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
